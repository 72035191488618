import type { StorageUserData } from '@vayapin/oauth-kit'
import useOAuthStore from './useOAuthStore'

function useUser(): Partial<StorageUserData> {
  return useOAuthStore((state) => state?.userData ?? {
    currentAccount: undefined,
    accounts: [],
  })
}

export default useUser
