import { createStore } from 'zustand/vanilla'
import OAuthKit, { isNumGt0, OAuthError, OAuthKitState } from '@vayapin/oauth-kit'
import Config from './Config'
import checkTokenTimeoutMilliseconds from './utils/checkTokenTimeoutMilliseconds'
import handleError from './utils/handleError'
import debugLog from './utils/debugLog'

export interface OAuthKitReactStore extends OAuthKitState {
  refresh: () => void
  syncState: () => void
}

let remoteCheckInterval: ReturnType<typeof setTimeout> | undefined = undefined

// ------------------------------------------------------
// Store / Default export
// ------------------------------------------------------
const oauthStore = createStore<OAuthKitReactStore>((set) => ({
  ...OAuthKit.getState(),
  refresh() {
    set((state) => ({ ...state, ...OAuthKit.getState() }))
  },
  syncState() {
    void OAuthKit.syncState()
  },
}))

export default oauthStore

// ------------------------------------------------------
// Callbacks / Helper
// ------------------------------------------------------

async function handleStoreUpdate() {
  oauthStore.getState().refresh()
  return Promise.resolve()
}

/**
 * Run remote validity check for the current stored token.
 * If there is no token available, it won't output anything
 * and will try again. If tokenRemoteCheckInterval is negative
 * it'll just return and don't do anything.
 */
async function checkRemoteValidity() {
  if (oauthStore.getState().signedIn === false) return
  if (oauthStore.getState().accessAllowed === false) return

  debugLog('oauthStore: Checking remote validity...')

  try {
    await OAuthKit.isTokenRemoteValid()
  } catch (e) {
    const err = e as OAuthError

    if ((err instanceof OAuthError) === false
        || err.reason !== 'LOCAL_TOKEN_INVALID') {
      handleError(err)
    }
  }
}
function checkRemoteValiditySync() {
  void checkRemoteValidity()
}

function createRemoteCheckInterval() {
  const tokenRemoteCheckIntervalMs = Config.get().tokenRemoteCheckInterval * 1000

  if (remoteCheckInterval) {
    clearTimeout(remoteCheckInterval)
  }

  if (isNumGt0(tokenRemoteCheckIntervalMs)) {
    remoteCheckInterval = setInterval(checkRemoteValiditySync, tokenRemoteCheckIntervalMs)
  }
}

// ------------------------------------------------------
// Setup
// ------------------------------------------------------

export function configureStore() {
  const tokenRemoteCheckIntervalMs = Config.get().tokenRemoteCheckInterval * 1000

  // 1. Subscribe to changes
  OAuthKit.unsubscribeChange(handleStoreUpdate)
  OAuthKit.subscribeChange(handleStoreUpdate)

  // 2. Sync the store once manually
  void OAuthKit.syncState()

  // 3. Set up interval logic to check remote validity
  checkTokenTimeoutMilliseconds().then((delta) => {
    if (!isNumGt0(tokenRemoteCheckIntervalMs)) return
    if (delta === false) delta = tokenRemoteCheckIntervalMs

    setTimeout(() => createRemoteCheckInterval(), delta)
  }).catch((e: Error) => handleError(e))
}
