import { useStore } from 'zustand'
import store from './oauthStore'

type ExtractState<S> = S extends {
  getState: () => infer T;
} ? T : never;

export default function useOAuthStore<U>(selector: (state: ExtractState<typeof store>) => U) {
  return useStore<typeof store, U>(store, selector)
}
