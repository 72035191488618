import type { Config as IConfig, ConfigConfigureOptions } from './Types'
import Config from './Config'
import { version } from './version'
import { configureStore } from './oauthStore'

/**
 * OAuthKit proxy module to handle the core logic of authentication.
 */
export class OAuthKitReact {
  version = version

  /**
   * Merges the default config with your passed configuration
   * object and saves it as current config.
   * @see {@link Config.configure}
   */
  configure(cfg: ConfigConfigureOptions, reset = false): IConfig {
    const config = Config.configure(cfg, reset)
    configureStore()
    return config
  }

  /**
   * Returns the current config
   */
  getConfig(): IConfig {
    return Config.get()
  }
}

//
// Singleton
const OAuthKitReactSingleton = new OAuthKitReact()
export default OAuthKitReactSingleton
