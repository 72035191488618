import OAuthKit from '@vayapin/oauth-kit'
import { PropsWithChildren, useEffect, useMemo } from 'react'

function OAuthKitProvider({ children }: Readonly<PropsWithChildren>) {
  useEffect(() => {
    void OAuthKit.syncState()
  }, [])

  return useMemo(() => children, [children])
}

export default OAuthKitProvider
