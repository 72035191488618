import { useCallback, useMemo } from 'react'
import OAuthKit, { NavigateSignInOptions } from '@vayapin/oauth-kit'

function useAuthHelper() {
  const oauthClearAllData = useCallback(async () => {
    await OAuthKit.clearAuthData()
    await OAuthKit.clearUserInfo()
  }, [])

  const oauthClearAuthData = useCallback(async () => {
    await OAuthKit.clearAuthData()
  }, [])

  const oauthClearUserInfo = useCallback(async () => {
    await OAuthKit.clearUserInfo()
  }, [])

  const oauthLocalSignOut = useCallback(async () => {
    await OAuthKit.localSignOut()
  }, [])

  const getSignInUri = useCallback(
    async (options?: NavigateSignInOptions) => await OAuthKit.getSignInUri(options),
    []
  )

  const getSignUpUri = useCallback(
    async (options?: NavigateSignInOptions) => await OAuthKit.getSignUpUri(options),
    []
  )

  const getSignOutUri = useCallback(async () => await OAuthKit.getSignOutUri(), [])

  return useMemo(() => ({
    oauthClearAllData,
    oauthClearAuthData,
    oauthClearUserInfo,
    oauthLocalSignOut,
    getSignInUri,
    getSignUpUri,
    getSignOutUri,
  }), [
    oauthClearAllData,
    oauthClearAuthData,
    oauthClearUserInfo,
    oauthLocalSignOut,
    getSignInUri,
    getSignUpUri,
    getSignOutUri,
  ])
}

export default useAuthHelper
