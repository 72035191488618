import { useCallback, useEffect, useRef, useState } from 'react'
import OAuthKit, { isPresent, OAuthError } from '@vayapin/oauth-kit'
import debugLog from './utils/debugLog'
import handleError from './utils/handleError'

export type State = 'authenticating' | 'error' | 'done';

export type Error = 'authorizationCodeMissing' | 'tokenAcquisitionFailed' | 'unknownError'

export interface UseOAuthCallbackResult<T> {
  /**
   * If the authentication is loading or not
   */
  loading: boolean;

  /**
   * State of the process.
   */
  state: State;

  /**
   * Error code
   */
  error?: Error;

  /**
   * OAuthKit error
   */
  authError?: OAuthError;

  /**
   * Data that you stored via {@link OAuthKit.getAfterSignInData}
   * before being redirected to the id service.
   */
  afterSignInData?: T;
}

async function fetchToken(code: string): Promise<true> {
  await OAuthKit.fetchOAuthTokenAndData(code)
  return true
}

const DEFAULT_RESULT: UseOAuthCallbackResult<unknown> = {
  loading: true,
  state: 'authenticating',
  error: undefined,
  authError: undefined,
  afterSignInData: undefined,
}

/**
 * Handle the OAuth authorization code callback
 * to fetch new token and user data.
 * @type T The Type of your data set via {@link OAuthKit.getAfterSignInData}
 */
export default function useOAuthCallback<T>(
  code?: string,
): UseOAuthCallbackResult<T> {
  const [result, setResult] = useState<UseOAuthCallbackResult<T>>(
    DEFAULT_RESULT as UseOAuthCallbackResult<T>
  )
  const loading = useRef(false)

  /**
   * Fetch token for code
   */
  const fetchTokenForCode = useCallback(async (code?: string) => {
    debugLog(['useOAuthCallback.fetchTokenForCode', code])

    if (!isPresent(code)) {
      setResult((r) => ({
        ...r,
        loading: false,
        state: 'error',
        error: 'authorizationCodeMissing',
      }))
      return
    }

    if (loading.current) return

    loading.current = true

    try {
      setResult(await _fetchTokenForCode(code))
    } catch (e) {
      setResult(_handleFetchTokenForCodeError(e, DEFAULT_RESULT as UseOAuthCallbackResult<T>))

      // Casting any kind of unknown error
      // @ts-expect-error
      handleError(e as Error)
    } finally {
      loading.current = false
    }
  }, [])

  // check code
  useEffect(() => {
    void fetchTokenForCode(code)
  }, [code, fetchTokenForCode])

  // Return data
  return result
}

/**
 * @private
 * Fetch token for code
 */
async function _fetchTokenForCode<T>(code?: string): Promise<UseOAuthCallbackResult<T>> {
  debugLog(['useOAuthCallback._fetchTokenForCode'])

  let tokenResult: boolean | OAuthError = false

  const result: UseOAuthCallbackResult<T> = {
    ...(DEFAULT_RESULT as UseOAuthCallbackResult<T>),
    loading: false,
  }

  tokenResult = await fetchToken(code ?? '')
  debugLog(['useOAuthCallback._fetchTokenForCode - result', tokenResult])

  if ((tokenResult as unknown as OAuthError) instanceof OAuthError) {
    result.state = 'error'
    result.error = 'tokenAcquisitionFailed'
    result.authError = tokenResult as unknown as OAuthError
  } else {
    result.state = 'done'
    result.afterSignInData = await OAuthKit.getAfterSignInData()
  }

  return result
}

/**
 * @private
 * Handle thrown errors
 */
function _handleFetchTokenForCodeError<T>(
  e: unknown,
  result: UseOAuthCallbackResult<T>,
): UseOAuthCallbackResult<T> {
  debugLog(['useOAuthCallback._handleFetchTokenForCodeError - error', e])

  if ((e instanceof OAuthError) === false) {
    // Casting any kind of unknown error
    // @ts-expect-error
    handleError(e)
  }

  const err = e as OAuthError

  if (err.reason === 'AUTHORIZATION_CODE_MISSING') {
    return {
      ...result,
      state: 'error',
      error: 'authorizationCodeMissing',
      authError: err,
    }
  }

  return {
    ...result,
    state: 'error',
    error: 'unknownError',
    authError: err,
  }
}
