import isObject from 'lodash/isObject'
import omit from 'lodash/omit'

import OAuthKit from '@vayapin/oauth-kit'

import type { Config as IConfig, ConfigConfigureOptions } from './Types'

export const DEFAULT_CONFIG: IConfig = {
  tokenRemoteCheckInterval: -1,
}

// Config object
let config = { ...DEFAULT_CONFIG }

/**
 * Get the config object
 */
export function get(): IConfig {
  return config
}

/**
 * Merges the default config with your passed configuration
 * object and saves it as current config.
 * @param cfg Your configuration
 * @return New current config
 */
export function configure(cfg: ConfigConfigureOptions, reset = false): IConfig {
  const oauthKitConfig = cfg.oauthKitConfig
  cfg = omit(cfg, 'oauthKitConfig')

  config = reset
    ? { ...DEFAULT_CONFIG, ...cfg }
    : { ...DEFAULT_CONFIG, ...config, ...cfg }

  if (isObject(oauthKitConfig)) OAuthKit.configure(oauthKitConfig)

  return config
}

/**
 * @private
 * Reset config to defaultConfig for testing purposes
 */
export function _testReset(
  newConfig: ConfigConfigureOptions = {} as ConfigConfigureOptions
): void {
  configure(newConfig, true)
}

// default exports
const Config = {
  DEFAULT_CONFIG,
  get,
  configure,
}

export default Config
