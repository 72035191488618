import OAuthKit, { isNumGt0 } from '@vayapin/oauth-kit'

import Config from '../Config'

async function checkTokenTimeoutMilliseconds(): Promise<number | false> {
  const { fetchedAt } = OAuthKit.getAuthInfo()
  const { tokenRemoteCheckInterval } = Config.get()
  const now = new Date().getTime()

  if (!isNumGt0(fetchedAt)) return false
  if (!isNumGt0(tokenRemoteCheckInterval)) return false

  const checkAt = (fetchedAt + tokenRemoteCheckInterval) * 1000

  return Promise.resolve(checkAt - now)
}

export default checkTokenTimeoutMilliseconds
